@import '/src/scss/variables.scss';

.payContractor-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 500px;
  padding-bottom: 100px;
  overflow-x: auto;
  //overflow-y: hidden;
  &-header {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 115%;
    margin-top: 24px;
  }

  .invoice-link {
    color: #137547;
  }
}

.payContractor-bottom-wrapper {
  border-top: 1px solid #efecef;
  box-shadow: 0px -1px 0px #efecef;
  position: fixed;
  bottom: 0;
  right: 0;
  background: white;
  width: calc(100% - 240px);
  display: flex;
  @media screen and (max-width: $medium_breakpoint_width) {
    width: calc(100% - 240px);
  }
}

.payContractor-bottom {
  padding: 32px 75px;
  width: 100%;
}

.copy_text {
  justify-content: space-between;
  width: 372px;
  background: #f5f5f5;
  border-radius: 12px;
  padding: 8px 12px;
}

.copy_text-icon {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(158, 193, 185, 0.08);
  border-radius: 8px;
}

.notification {
  color: #ffa530;
  background-color: #ffe8c2;

  svg path {
    fill: #ffa530;
  }
}

.notification-info {
  color: #4c92c7;
  background-color: #ebf5fc;

  svg path {
    fill: #4c92c7;
  }
}
