.onboarding-steps {
  display: flex;
  justify-content: space-between;
  margin: 32px 0;
  .step-card {
    display: flex;
    flex-direction: column;
    width: 32%;
    padding: 16px;
    border-radius: 8px;
    gap: 10px;
    border: 1px solid #1f1f1f14;
    cursor: pointer;
    &__completed {
      display: flex;
      flex-direction: column;
      border: 1px solid #40b84c;
      background: #40b84c0d;
      width: 32%;
      padding: 16px;
      border-radius: 8px;
      gap: 10px;
      cursor: pointer;
    }
  }
}
