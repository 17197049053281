@-webkit-keyframes autofill {
  0%,
  100% {
    color: #666;
    background: transparent;
  }
}

.input {
  position: relative;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  label {
    position: relative;
    top: 0px;
    left: 0px;
    color: #121212;
    display: block;

    &.add-text {
      margin-bottom: 0;
    }
  }
  .input-wrapper {
    height: 40px;
    width: 100%;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    transition: border-bottom-color 0.25s;
    border-radius: inherit;
  }
  .input_outlined {
    border: none;
    input {
      padding: 0;
    }
  }
  input {
    width: 100%;
    height: 40px;
    padding: 0px 9px 0px 12px;
    transition: border-bottom-color 0.25s;
    background: transparent;
    outline: none;
    border: none;
    color: #121212;
    font-size: 16px;

    &::placeholder {
      color: #878787;
      line-height: normal;
    }

    &:disabled {
      background-color: #f5f5f5;
      color: #484848;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background-color: white !important;
      color: #121212 !important;
      height: 30px;
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    color: #878787;
  }
  .currency {
    justify-content: center;
    align-content: center;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 34px;
    height: 20px;
  }
  &-end-icon {
    position: absolute;
    right: 12px;
  }
}
