.button {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: inline-flex;
  border: 0;
  cursor: pointer;
  text-decoration: none;
  border-radius: 8px;
  appearance: none;
  background: none;
  white-space: nowrap;
  transition: 0.1s background-color;
  min-width: 50px;
  outline: none;
  font-style: normal;
  font-family: Manrope;
}

.button__large {
  height: 60px;
  padding: 18px 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: 115%;
}
.button__small {
  height: 40px;
  padding: 12px 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 115%;
}
.button__xsmall {
  height: 32px;
  padding: 6px 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 19.5px;
}
.button__medium {
  height: 48px;
  padding: 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.button__bordered {
  border: solid 1px #40b84c;
  color: #40b84c;
}

.button__primary {
  background-color: #40b84c;
  color: white;
  &:hover {
    background: #04b535;
  }
  &:active {
    background: #03b434;
  }
  &:disabled {
    background: #03b434;
    opacity: 0.4;
    cursor: default;
  }
}
.button__primary_black {
  background-color: #121212;
  color: white;
  &:hover {
    background: #1c1c1c;
  }
  &:active {
    background: #272727;
  }
  &:disabled {
    background: #121212;
    opacity: 0.25;
    cursor: default;
  }
}

.button__primary_dangerous {
  background-color: #f84f39;
  color: white;
}
.button__outlined {
  background-color: transparent;
  color: #40b84c;
  min-width: fit-content;
}

.button__secondary {
  background-color: #ffffff;
  color: #121212;
  border: 2px solid #f3f3f3;
  box-sizing: border-box;
  &:hover {
    background: #fafafa;
  }
  &:active {
    background: #f9f9f9;
  }
  &:disabled {
    opacity: 0.25;
    cursor: default;
  }
}

.button__danger {
  background-color: #f84f39;
  color: white;
  &:hover {
    background: #f84f39de;
  }
  &:active {
    background: #f84f39de;
  }
  &:disabled {
    background: #f84f39;
    opacity: 0.25;
    cursor: default;
  }
}
.button__danger_outlined {
  background-color: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  color: #f84f39;
  &:hover {
    background: #f84f39;
    color: #ffffff;
  }
  &:disabled {
    opacity: 0.25;
    cursor: default;
    background: #ffffff;
    border-color: #ccc;
    color: #f84f39;
  }
}

.label {
  display: inherit;
  justify-content: inherit;
  flex-direction: inherit;
  align-items: inherit;
  width: 100%;

  svg {
    height: auto;
  }
}
