.select {
  position: relative;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    position: relative;
    top: 0;
    left: 0;
    color: #121212;
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transform-origin: top left;
    display: block;
  }
  select {
    width: 100%;
    height: 40px;
    margin-top: 12px;
    padding: 0 9px 0 12px;
    transition: border-bottom-color 0.25s;
    border-radius: inherit;
    background: transparent;
    outline: none;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    font-size: 16px;

    &:disabled {
      color: gray;
    }
    &:after {
      contain: content;
      width: 20px;
      height: 40px;
    }
    &:required:invalid {
      color: gray;
    }
    option[value=''][disabled] {
      display: none;
      color: #8a8886;
    }
    option {
      color: #121212;
      min-height: 40px;
      max-height: 40px;
    }
  }
}

.react-select__option {
  background-color: white !important;
}

.react-select__option--is-focused {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.react-select__option--is-selected {
  color: #121212 !important;
  background-color: #f3f3f3 !important;
}

.react-select__value-container {
  font-size: 1rem;
}
