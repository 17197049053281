@import './variables.scss';

.color_primary {
  color: #137547;
}
.color_primary_dark {
  color: #004e08;
}
.color_primary_light {
  color: #cde5cf;
}
.color_primary_ultra_light {
  color: #e6f2e7;
}
.color_secondary_light {
  color: #40b84c;
}
.color_text_300 {
  color: #878787;
}
.color_text_200 {
  color: $color_200;
}
.color_black {
  color: #1e1d1c;
}
.color_black_light {
  color: #605e5c;
}
.color_black_ultra_light {
  color: #8a8886;
}
.color_grey {
  color: #878787;
}
.color_grey_light {
  color: #e1e1e1;
}
.color_grey_ultra_light {
  color: #f8f7f8;
}
.color_blue {
  color: #2967bc;
}
.color_blue_light {
  color: #eff3f8;
}
.color_red {
  color: #ef4343;
}
.color_red_light {
  color: #f1998e;
}
.color_red_ultra_light {
  color: #f9ede3;
}
.color_white {
  color: #ffffff;
}
.color_shadow {
  color: rgba(84, 95, 122, 0.08);
}
.color_modal_bg {
  color: rgba(0, 0, 0, 0.75);
}
.color_gray {
  color: #6d6d6c;
}
.color_dark_red {
  color: #d6331f;
}
.color_warning {
  color: #ffa530;
}
