.not-found {
  width: 100%;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.not-found_content {
  width: 320px;
  height: 290px;
  text-align: center;
}
