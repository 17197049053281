.modalSideBox {
  width: 640px;
  position: absolute;
  top: 0;
  right: 0;
  max-height: 100%;
  min-height: 100%;
  overflow: auto;
  background: white;
  display: flex;
  flex-direction: column;

  @media (max-width: 505px) {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    overflow: auto;
  }
}

.modalBox_full {
  height: 100%;
  width: 100%;
}

.modalSideWrapper {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  min-height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.2);
  display: block;
  z-index: 999;
}

.modalSideHeader {
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 24px;
  justify-content: space-between;
  background: #f6f6f6;
  z-index: 2;

  svg {
    cursor: pointer;
  }
}
.modalSideContent {
  padding: 32px;
  display: flex;
  overflow: auto;
  flex: 1;
  flex-direction: column;
}

.modalSideFooter {
  background-color: white;
  bottom: 0;
  width: 100%;
  padding: 16px 24px;
  border-top: 1px solid #e0e3ea;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
