.react-datepicker__input-container {
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  box-sizing: border-box;
  transition: border-bottom-color 0.25s;
  input {
    width: 100%;
    height: 40px;
    padding: 0px 9px 0px 12px;
    transition: border-bottom-color 0.25s;
    background: #fff;
    border-radius: 8px;
    outline: none;
    border: none;
    color: #121212;
    font-size: 16px;

    &:disabled {
      background-color: hsl(0, 0%, 95%);
    }
  }
}

.datepicker_full-width {
  .react-datepicker-wrapper {
    width: 100%;
  }
}
