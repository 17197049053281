table {
  width: 100%;
  text-align: left;

  th {
    border-top: 0;
    border-bottom: 1px solid #f3f3f3;
    height: 70px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #878787;
  }

  th:last-child {
    div {
      // justify-content: end;
    }
  }

  tr {
    height: 70px;
    td:last-child {
      div {
        // display: inline-block;
      }
    }
  }

  td {
    vertical-align: middle;
    border-bottom: 1px solid #f3f3f3;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #121212;
    div {
      font-family: 'Inter', sans-serif !important;
    }
  }
  .cursor-pointer {
    cursor: pointer;
    &:hover {
      background: #fafafa;
    }
    &:active {
      background: #dfdfdf;
    }
  }
}
.table_header {
  &:hover {
    .sort_icon {
      visibility: visible;
      display: block;
    }
  }
  .sort_icon {
    visibility: hidden;
    display: block;
  }
  .server_sort_icon {
    visibility: visible;
    display: block;
  }
}
.table-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}
.status_inactive {
  opacity: 0.67;
}

.additional_tag {
  font-size: 10px;
  color: #026aa2;
  background: #f0f9ff;
  border-radius: 4px;
  padding: 0 6px;
  margin-left: 8px;
}
