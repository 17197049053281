.edit-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  position: relative;

  &--loading {
    border: 1px solid #cccccc;
  }

  &__edit {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.5;
    border-radius: 50%;
  }

  &__img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  &__input {
    display: none;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}
