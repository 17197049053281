@import '/src/scss/variables.scss';
@import 'bootstrap/scss/mixins/_container.scss';
@import 'bootstrap/scss/bootstrap-grid.scss';

.custom-container {
  padding-top: 30px;
  padding-bottom: 80px;
  height: 100%;
  @extend .container;
}

.page-content {
  padding: 20px 20px 80px;
}

.acc-deactivate {
  background: #eff3f8;
  height: 55px;
  padding: 16px 80px;
  cursor: pointer;
}
