.container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

@mixin step {
  position: absolute;
  left: -15px;
  z-index: 2;
  background-color: #fff;
  max-width: 32px;
  min-width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step {
  @include step;
}

.success {
  @include step;
  background: #e6f2e7;
  border-radius: 50%;
}

.progressBar {
  .progress .progress-bar {
    background-color: #137547;
  }
}
.content {
  min-height: 70px;
  width: 100%;
  padding-left: 30px;
}

.divider {
  border-left: solid 1px #b2b2b2;
}

.expandable {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  margin-top: unset !important;
  justify-content: space-between;
  align-items: center;
}
