.customers-detail-page {
}

.team-tab {
  &-table {
    tbody {
      tr {
        &:first-child {
          td {
            &:last-child {
              button {
                &:last-child {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
