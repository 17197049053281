@import '/src/scss/variables.scss';

.employees-time-page {
  &-header {
    margin-top: 24px;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 115%;
  }
}

.expense-table-wrap {
  svg {
    color: #121212;
    &:hover {
      color: #40b84c;
    }
  }
}

.expense-table-header {
  display: flex;
  flex-direction: row;
}

.expense-table-box {
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 100%;
}

.expenseEvent {
  .content-body {
    background: #ffffff;
    box-shadow: 0px 12px 24px rgba(22, 22, 67, 0.04);
    border-radius: 8px;
    padding: 16px;
  }
}

.link-public-holidays {
  color: #137547;
  text-decoration: underline;
  cursor: pointer;
}

.modal-90w {
  width: 576px;
  min-height: 500px;
  max-height: 500px;
  height: 500px;
  .modal-content {
    height: 100%;
  }
}

.dialog-modal {
  display: flex !important;
  align-items: center;
}
.toggle-bg {
  background: #f5f5f5;
  padding: 12px 16px;
  border-radius: 8px;
}
