.success {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px 12px 24px;
  gap: 32px;
  width: 452px;
  height: 44px;
  background: #dbfcee;
  border: 1px solid #2db77b;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
