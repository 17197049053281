.status_ap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  font-weight: 600;
  padding: 4px 12px;
  border-radius: 16px;
  border: 1px solid;
  font-size: 12px;
  line-height: 16px;
}

.status_created_ap {
  border-color: #497cf6;
  background: #ffffff;
  color: #497cf6;
}

.status_approved_ap {
  border-color: #2db77b;
  background: #ffffff;
  color: #2db77b;
}

.status_to_be_paid_next_month_ap {
  border-color: #ffa530;
  background: #ffffff;
  color: #ffa530;
}

.status_to_be_paid_this_month_ap {
  background: #ffa530;
  color: #ffffff;
}

.status_invoiced_ap {
  background: #83aa36;
  color: #ffffff;
}

.status_paid_ap {
  background: #2db77b;
  color: #ffffff;
}

.status_cancelled_ap {
  background: #f24822;
  color: #ffffff;
}
