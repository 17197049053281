.employee-detail-page {
  padding-right: 30px;
  &__edit-avatar {
    align-self: flex-start;
    margin-bottom: 20px;
  }
}
.onboarding-document-container {
  .document {
    background-color: #1f1f1f0a;
    border: 1px solid #1f1f1f14;
    border-radius: 8px;
    padding: 16px;
    max-width: 560px;
    .header {
      display: flex;
      justify-content: space-between;
      padding: 15px 0;
      align-items: center;
    }
    .files {
      background-color: #ffffff;
      border-radius: 4px;
      padding: 8px 16px;
      a {
        color: #40b84c;
      }
    }
  }
}
