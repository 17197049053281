.invoices-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-header {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 115%;
    margin-top: 24px;
  }
  &-search {
    width: 248px;
  }
}

.invoice-status {
  background: #e6f2e7;
  color: #137547;
  padding: 2px 4px;
}

.employee-label {
  color: #40b84c;
}

.back-btn {
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  width: 40px;
  cursor: 'pointer';
}
