.not-generated-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 50px 0;
  .svg-container {
    margin-bottom: 10px;
  }
  .text-heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #121212;
  }
  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #8f8f8f;
  }
}
