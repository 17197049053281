.payment-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;

  width: 100%;

  background: #f9f9f9;
  border-radius: 12px 12px 0px 0px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.payment-toggle {
  padding: 4px 16px;
  height: 68px;
  width: 100%;

  /* BG/100 */

  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(15, 15, 16, 0.04);
  border-radius: 8px;
}

.payment-info {
  display: flex;
  padding: 12px 16px;

  width: 100%;

  /* BG/100 */

  background: #ffffff;
  box-shadow:
    0px 2px 4px rgba(15, 15, 16, 0.04),
    0px 2px 4px rgba(15, 15, 16, 0.04);
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.payment-methods {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;

  width: 325px;

  /* BG/100 */

  background: #ffffff;
  box-shadow:
    0px 2px 4px rgba(15, 15, 16, 0.04),
    0px 2px 4px rgba(15, 15, 16, 0.04);
  border-radius: 12px;
}

.transaction-history {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  margin-top: 4px;
  width: 100%;

  background: #f9f9f9;
  border-radius: 12px 12px 0px 0px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.history-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  height: 500px;

  /* BG/100 */

  background: #ffffff;
  box-shadow:
    0px 2px 4px rgba(15, 15, 16, 0.04),
    0px 2px 4px rgba(15, 15, 16, 0.04);
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.history-table {
  width: 100%;
  background: #ffffff;
  box-shadow:
    0px 2px 4px rgba(15, 15, 16, 0.04),
    0px 2px 4px rgba(15, 15, 16, 0.04);
  border-radius: 12px;
  border: 1px solid #ebebeb;
}

.modal-invoice {
  width: 954px;
  max-width: 954px;
}

.invoice-table {
  width: 904px;
  display: flex;
  flex-direction: column;
}

.invoice-table-header {
  display: flex;
  width: 100%;
}

.head-title {
  padding: 12px 24px;
  background: #f5f5f5;
  height: 72px;
  display: flex;
  align-items: center;
}

.body-title {
  padding: 12px 24px;
  height: 72px;
  display: flex;
  align-items: center;
}
.w-15 {
  width: 15% !important;
}
