table {
  width: 100%;
  text-align: left;

  tbody {
    border: 1px solid #f3f3f3;
  }

  th,
  td {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #878787;
    border-bottom: 1px solid #f3f3f3;
    padding: 10px;
    vertical-align: middle;

    // &:first-child {
    //   border-left: 1px solid #f3f3f3;
    // }

    // &:last-child {
    //   border-right: 1px solid #f3f3f3;
    // }

    div {
      font-family: 'Inter', sans-serif !important;
    }
  }

  td {
    font-weight: 400;
    color: #121212;
    word-break: break-word;
  }

  .cursor-pointer {
    cursor: pointer;

    &:hover {
      background: #fafafa;
    }

    &:active {
      background: #dfdfdf;
    }
  }

  .status_inactive {
    opacity: 0.67;
  }

  .table_header {
    &:hover .sort_icon {
      visibility: visible;
    }

    .sort_icon {
      visibility: hidden;
    }
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
}

.time-table {
  .table-title {
    height: 60px;
    padding: 10px;
    border-bottom: 1px solid #ebebeb;
    background-color: #f5f5f5;
  }

  .table-typography {
    color: black;
  }

  .divider {
    color: #adadad;
  }

  .total-hours {
    color: #137547;
  }

  .table-column-header {
    background-color: #f5f5f5;
  }

  .table-column {
    height: 60px;
  }
}
