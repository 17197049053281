.employees-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-header {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 115%;
    margin-top: 24px;
  }
  &-search {
    width: 248px;
  }

  &__form {
    width: 600px;
  }

  &__form-container {
    width: 660px;
  }
}
