.chat {
  height: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: space-between;
}

.aside {
  flex-basis: 340px;
  border-right: solid 1px #f3f3f3;
  display: flex;
  flex-direction: column;

  &_threads {
    flex: 1 1 10px;
    overflow: auto;
  }
}

.messages {
  flex-grow: 1;
  padding: 0 10px 0 10px;
  display: flex;
  min-height: 90vh;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.message_content {
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
}

.thread {
  border-radius: 8px;
  padding: 16px 20px;
  width: 100%;
  cursor: pointer;
}

.thread_selected {
  background: #f5f5f5;
}

.chat_thread_count {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: #ffffff;
  background: #40b84c;
  display: flex;
  justify-content: center;
  align-items: center;
}
