aside {
  background-color: rgb(245, 245, 245);
}

.stepsFooter {
  padding: 1.5rem 2rem;
  border-top: 1px solid #dfdfdf;
}

.logout {
  display: flex;
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  color: #878787;
}
