.collapse-table {
  width: 100%;
  text-align: left;

  & > th {
    border-top: 0;
    border-bottom: 1px solid #f3f3f3;
    height: 70px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #878787;
  }

  .collapse-table-row {
    height: 70px;
    margin-bottom: 16px;
  }

  & > td {
    vertical-align: middle;
    border-bottom: 1px solid #f3f3f3;
    border-top: 1px solid #f3f3f3;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #121212;
  }
  .cursor-pointer {
    cursor: pointer;
    &:hover {
      background: #fafafa;
    }
    &:active {
      background: #dfdfdf;
    }
  }
  .collapse-content {
    background: #f9f9f9;
    padding: 16px;
  }
}
.table_header {
  &:hover {
    .sort_icon {
      display: block;
    }
  }
  .sort_icon {
    display: none;
  }
}

.status_inactive {
  opacity: 0.67;
}
