.status_expense {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  font-weight: 600;
  padding: 4px 12px;
  border-radius: 16px;
  border: 1px solid;
  font-size: 12px;
  line-height: 16px;
}

.status_created_expense {
  border-color: #0d99ff;
  background: #ffffff;
  color: #0d99ff;
}

.status_approved_expense {
  border-color: #2db77b;
  background: #ffffff;
  color: #2db77b;
}

.status_to_be_paid_next_month_expense {
  border-color: #ffa530;
  background: #ffffff;
  color: #ffa530;
}

.status_to_be_paid_this_month_expense {
  background: #ffa530;
  color: #ffffff;
}

.status_invoiced_expense {
  background: #83aa36;
  color: #ffffff;
}

.status_paid_expense {
  background: #2db77b;
  color: #ffffff;
}

.status_canceled_expense {
  background: #f24822;
  color: #ffffff;
}

.status_deleted_expense {
  background: #6d6d6c;
  color: #ffffff;
}
