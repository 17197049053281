.cardItem {
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.secondary {
  background-color: #f5f5f5;
}
