.styledCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  background: transparent;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  transition: all 150ms;
}

.styledCheckbox__disabled {
  background-color: rgb(209 209 209);
  opacity: 0.3;
  cursor: not-allowed;
}
