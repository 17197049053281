@import 'scss/base.scss';
@import 'scss/colors.scss';
@import 'scss/variables.scss';

a:hover {
  color: #121212;
}

.remo-form-label {
  margin-bottom: 8px;
}
.css-xjimrv-Switch {
  width: 40px !important;
  height: 20px !important;
}
.css-153wf2s-Switch,
.css-jfsaru-Switch {
  border-radius: 20px !important;
}
.css-1v96km2-Switch {
  left: 22px !important;
  width: 16px !important;
}
.css-19a995p-Switch {
  width: 16px !important;
}
.css-jfsaru-Switch {
  background-color: #40b84c !important;
}
.remo-form-input {
  width: 100%;
  margin-bottom: 16px;
}
.remo-form {
  width: 600px;
}

.select {
  .react-select {
    &__control {
      border-radius: 8px;
      font-size: 14px;
      line-height: 20px;
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      color: #878787;
    }
  }
}

.progress {
  flex: 1;
  height: 100%;
  background: #fff;

  .progress-bar {
    background: #f5f5f5;
    color: #121212;
  }
}

.modal-content {
  padding: 16px;
  border-radius: 16px !important;
}
.modal-header {
  border-bottom: none;
  margin-bottom: 16px;
}
.modal-footer {
  border-top: none;
}

#chat_threads {
  flex: 1 1 10px;
  overflow: auto;
}

.progress {
  flex: 1;
  height: 100%;
  background: #fff;

  .progress-bar {
    background: #f5f5f5;
    color: #121212;
  }
}

.card {
  border-radius: 8px;
  border-color: rgb(142 142 142 / 13%);

  .card-header {
    border-bottom: none;
    .card-title {
      font-family: 'Manrope', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 115%;
      margin-bottom: unset;
    }
  }
}

.alert {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 12px 16px;
  border: none;
}

a.link {
  color: $primary_light;
  text-decoration: none;
}

.tooltip-large .tooltip-inner {
  max-width: 400px;
  text-align: left;
}
