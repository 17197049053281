@mixin border($line) {
  border-#{$line}: solid 1px #dfdfdf;
}

.aside {
  display: flex;
  flex-direction: column;
  width: 578px;
  background: linear-gradient(0deg, #faf9f6, #faf9f6);

  &__title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -1px;
    width: 380px;
    margin-top: 100px;
  }

  &__description {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 162.5%;
    color: #878787;
    margin-top: 4px;
  }
}

.main {
  flex-grow: 1;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  &-container {
    width: 480px;
  }
}

.logout {
  @include border(top);
  padding: 27px 33px;
  color: #878787;
}
.success {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 374px;
  text-align: center;
}
