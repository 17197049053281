@import '/src/scss/variables.scss';

.textArea {
  position: relative;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    position: relative;
    display: block;
    color: $black;
  }
  textarea {
    width: 100%;
    padding: 8px 9px 0px 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    transition: border-bottom-color 0.25s;
    border-radius: inherit;
    background: transparent;
    outline: none;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    font-size: 16px;
    background-color: #fff;

    &::placeholder {
      color: #878787;
      line-height: normal;
    }

    &:disabled {
      color: gray;
      background-color: hsl(0, 0%, 95%);
    }
  }
}
