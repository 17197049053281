@import '/src/scss/variables.scss';

.overview-page {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
  &-employee-stats {
    display: flex;
    margin-bottom: 16px;
    gap: 15px;
    flex-wrap: nowrap;
    &__card {
      padding: 24px;
      height: 188px;
      border: 1px solid #eaecf0;
      border-radius: 8px;
      // min-width: 400px;
      // width: 400px;
    }
  }

  // @media (max-width: 1010px) {
  //   .overview-page-employee-stats {
  //     flex-wrap: nowrap;
  //   }
  // }

  &-payrolls {
    // min-width: 60%;
  }

  &-active-workers {
    // width: 430px;
    // min-width: 430px;
    // margin-left: 15px;
  }

  &-alert {
    border: 2px solid #f3f3f3;
    border-radius: 8px;
    padding: 16px 24px;
    background-color: #f9f9f9;
  }
}

.overview-page__content {
  margin: 0;
}

.overview-page-stats {
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 24px;
}

.divider {
  height: 1px;
  background: #f3f3f3;
}

.flag-icons {
  background-position: 50%;
  background-size: cover;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.overview-progress {
  background: #f5f5f5;
  border-radius: 2px;
  height: 8px;
  display: flex;
  overflow: hidden;
}

.overview-progress-bar {
  background: #137547;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
}
