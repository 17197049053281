.card {
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  padding: 40px;
  min-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.datetime {
  color: #878787;
  font-size: 14px;
  margin-bottom: 24px;
}
