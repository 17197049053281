@import '/src/scss/variables.scss';

.customer-payroll {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 124px;
  &-header {
    display: flex;
    flex-direction: row;
  }
  &-divider {
    width: 100%;
    margin-top: 30px;
    height: 1px;
    background: #efecef;
  }
  &-table-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 12px;
  }
  &-header-row {
    display: flex;
    flex-direction: row;
    background: #f8f7f8;
    height: 72px;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 1px solid #e1e1e1;
  }
  &-content-row {
    display: flex;
    flex-direction: row;
    background: white;
    height: 72px;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
  }

  &-table-wrap {
    svg {
      color: #121212;
      &:hover {
        color: #40b84c;
      }
    }
  }

  &-table {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px;
  }

  .invoice-link {
    color: #137547;
  }

  @media screen and (max-width: $medium_breakpoint_width) {
    &-table {
      overflow-y: auto;
      overflow-x: auto;
    }
  }
}

.payroll-bottom-wrapper {
  border-top: 1px solid #efecef;
  box-shadow: 0px -1px 0px #efecef;
  position: relative;
  background: white;
  width: calc(100vw - 240px);
  display: flex;
  max-width: 1320px;
}

.payroll-bottom {
  padding: 32px 75px;
  width: 100%;
}

.customer-payroll-wrapper {
  border: 1px solid #adadad;
}

.customer-payroll-wrapper-header {
  background: #adadad;
  font-size: 16px;
  font-weight: 800;
  color: white;
  padding: 8px;
}
.customer-payroll-wrapper-description {
  background: rgba(31, 31, 31, 0.04);
  font-size: 22px;
  font-weight: 500;
  color: #6d6d6c;
  padding: 24px;
  border-bottom: 1px solid #adadad;
}

.customer-payroll-wrapper-approve {
  border: 1px solid rgba(255, 165, 48, 1);
}

.customer-payroll-wrapper-header-approve {
  background: rgba(255, 165, 48, 1);
  font-size: 16px;
  font-weight: 800;
  color: white;
  padding: 8px;
}
.customer-payroll-wrapper-description-approve {
  background: rgba(255, 236, 229, 1);
  font-size: 22px;
  font-weight: 500;
  color: black;
  padding: 24px;
  border-bottom: 1px solid #adadad;
}
