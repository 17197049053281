.chat_header {
  padding-bottom: 16px;
  border-bottom: 1px solid #f3f3f3;
}

.message {
  min-width: 544px;
  max-width: 544px;
}

.chat_message__user {
  background: #e6f2e7;
  border-radius: 8px;
  padding: 8px 16px;
  color: #121212;
  float: right;
}

.chat_message {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 8px 16px;
  float: left;
}
.chat_message__member {
  float: none;
  background: none;
  max-width: 100%;
  min-width: 100%;
}

.message_content {
  overflow: auto;
  display: flex;
  //flex: 1 1 10px;
  flex-direction: column-reverse;
}

.clearfix {
  clear: both;
}
.chat_wrapper {
  flex: 1 1 auto;
  height: 100%;
  padding-top: 32px;

  //@media screen and (max-height: 900px) {
  //  height: calc(100% - 70px);
  //}
}

.chat_textarea {
  display: flex;
  flex-direction: column;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  padding: 8px 24px 8px;
  label {
    display: none;
  }
  textarea {
    border: none;
    padding: 0;
    border-radius: 0;
  }
}

.chat-file {
  background-color: white;
  width: 100%;
  margin-top: 12px;
}

.msg_line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #f3f3f3;
  line-height: 0.1em;
  margin: 10px 0 20px;
  height: 13px;
}
