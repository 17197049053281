.burden {
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 8px;
  &_header {
    padding: 16px;
    border-bottom: 1px solid #dfdfdf;
  }
  &_content {
    min-height: 190px;

    &__row {
      padding: 16px;
    }
  }

  &_total {
    background-color: #f5f5f5;
  }
}
