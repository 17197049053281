table {
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;

  thead {
    background-color: #f5f5f5;

    th {
      height: 48px;
    }

    tr {
      &:first-child {
        th:first-child {
          border-top-left-radius: 10px;
        }

        th:last-child {
          border-top-right-radius: 10px;
        }
      }
    }
  }

  tbody {
    border: 1px solid #f3f3f3;

    tr {
      height: 48px;

      &:last-child {
        td:first-child {
          border-bottom-left-radius: 10px;
        }

        td:last-child {
          border-bottom-right-radius: 10px;
        }
      }
    }

    .radio-btn {
      height: 16px;
      width: 16px;
    }

    .radio-btn-cell {
      text-align: center;
    }
  }
}

table tr {
  height: 48px;
}
