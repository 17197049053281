.custom-input {
  position: absolute;
  z-index: 10;
  top: 28px;
  width: 470px;
  height: 36px;
}

.milestones-wrapper {
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
  justify-content: center;
}

.milestone-actions-wrapper {
  display: flex;
  gap: 16px;
}
