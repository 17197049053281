.modalBox {
  width: 505px;
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 100%;
  min-height: 100%;
  overflow: auto;
  background: white;
  transform: translate(-50%, -50%);

  @media (max-width: 505px) {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    overflow: auto;
  }
}

.modalBox_full {
  height: 100%;
  width: 100%;
}

.modalWrapper {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.2);
  display: block;
  z-index: 999;
}
