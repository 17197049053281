$primary: #137547;
$primary_dark: #004e08;
$primary_light: #40b84c;
$primary_ultra_light: #e6f2e7;
$black: #1e1d1c;
$black_light: #605e5c;
$black_ultra_light: #8a8886;
$grey: #afafaf;
$grey_light: #e1e1e1;
$grey_ultra_light: #f8f7f8;
$blue: #2967bc;
$blue_light: #eff3f8;
$red: #e11900;
$red_light: #f1998e;
$red_ultra_light: #f9ede3;
$white: #ffffff;
$shadow: rgba(84, 95, 122, 0.08);
$modal_bg: rgba(0, 0, 0, 0.75);
$color_200: #484848;

// Breakpoint with for adaptive, the main purpose to remove sidebar
// for content to intervene, please check design system
$medium_breakpoint_width: 1496px;
